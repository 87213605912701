import React from "react"

import Layout from "../../layouts/Fi"

import SEO from "../../components/SEO"

const KiitosPage = () => (
  <Layout title="Kiitos yhteydenotostasi">
    <SEO
      keywords={[`kiitos`, `yhteydenotostasi`]}
      title="Kiitos yhteydenotostasi"
    />

    <h3 className="text-center">Vastaamme viestiisi mahdollisimman pian.</h3>
  </Layout>
)

export default KiitosPage
